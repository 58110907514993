import type { SwiperAndGridBreakpoints } from '@mwl/core-lib';

export const breakpoints: SwiperAndGridBreakpoints = {
  0: {
    swiperCardMinWidth: '1fr',
    swiperCardMaxWidth: '1fr',
    slidesPerView: 1,
    spaceBetween: 8,
  },
  360: {
    swiperCardMinWidth: '318px',
    swiperCardMaxWidth: '318px',
    slidesPerView: 'auto',
    spaceBetween: 8,
  },
  768: {
    swiperCardMinWidth: '376px',
    swiperCardMaxWidth: '376px',
    slidesPerView: 'auto',
    spaceBetween: 8,
  },
};
